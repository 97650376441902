import { Injectable } from '@angular/core';
import { GoogleMapsService } from './google-maps.service';
import { environment } from '@environment';
import { MapLocation } from '../models/map-location.model';
import { MapOptions } from '../models/map-options.model';
import { MAP_STYLES } from '../models/map-styles';
import { Map } from '../models/map.model';
import { from, Observable } from 'rxjs';
import {
  DEFAULT_ACTIVE_MATERIAL_ICON_MARKER,
  DEFAULT_MATERIAL_ICON_MARKER,
} from '../constants/default-material-icon-marker';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private readonly MARKER_URL = environment.markerUrl;
  private readonly ACTIVE_MARKER_URL = environment.markerActiveUrl;
  private readonly DEFAULT_ICON_SIZE = { x: 30, y: 30 };
  private readonly DEFAULT_ACTIVE_ICON_SIZE = { x: 80, y: 80 };
  private readonly MIN_ZOOM = 13;
  private readonly MAP_PADDING = 64;

  constructor(private googleMapsService: GoogleMapsService) {}

  createMap(mapElement: HTMLElement, locations: MapLocation[], options?: MapOptions): Observable<Map> {
    return from(this.initMap(mapElement, locations, options));
  }

  fitBounds(map: Map) {
    if (map.markerBounds && map.markerList.length > 1) {
      map.googleMap?.fitBounds(map.markerBounds, this.MAP_PADDING);
      this.resetZoom(map);
    }
  }

  async clearAllMarkers(map: Map) {
    map.markerList.forEach(({ marker }) => marker.setMap(null));
    map.markerList = [];
    map.markerBounds = await this.googleMapsService.getLatLngBounds();
  }

  async addMarkers(map: Map, locations: MapLocation[], fitBounds = true) {
    for (let i = 0; i < locations.length; i++) {
      const latLng = await this.googleMapsService.getLatLng(locations[i].latitude, locations[i].longitude);
      const active = i === 0 && !!map.options?.includeActiveMarker;
      const marker = await this.createMarker(map, locations[i], i, active);
      map.markerList.push({ marker, active, location: locations[i] });
      map.markerBounds?.extend(latLng);
    }
    if (fitBounds) {
      this.fitBounds(map);
    }
  }

  async addMarkerWithBounds(
    map: Map,
    location: google.maps.LatLng,
    bounds: google.maps.LatLngBounds | null = null,
    icon?: string,
  ) {
    const mapLocation: MapLocation = {
      latitude: location.lat(),
      longitude: location.lng(),
      icon: icon,
    };
    const marker = await this.createMarker(map, mapLocation, 0, false);
    map.markerList.push({ marker, active: false, location: mapLocation });
    bounds ? map.googleMap?.fitBounds(bounds) : map.googleMap?.setCenter(location);
  }

  destroyMap(map: Map): void {
    map.googleMap.getDiv()?.remove();
    this.googleMapsService.clearAllMapEvents(map.googleMap);
    map.markerList.forEach(({ marker }) => marker.setMap(null));
    map.markerList = [];
  }

  private async initMap(mapElement: HTMLElement, locations: MapLocation[], options?: MapOptions): Promise<Map> {
    const mapOptions: MapOptions = {
      allowMarkerSelection: true,
      center: locations.length
        ? await this.googleMapsService.getLatLng(locations[0].latitude, locations[0].longitude)
        : null,
      clickableIcons: false,
      disableDefaultUI: true,
      gestureHandling: options?.allowGestures ? 'auto' : 'none',
      mapTypeId: await this.googleMapsService.getMapTypeId(),
      styles: MAP_STYLES,
      zoom: this.MIN_ZOOM,
      zoomControl: false,
      keyboardShortcuts: false,
      ...options,
    };

    const mapInstance = await this.googleMapsService.getMap(mapElement, mapOptions);
    const markerBounds = await this.googleMapsService.getLatLngBounds();

    const map: Map = {
      googleMap: mapInstance,
      markerBounds: markerBounds,
      markerList: [],
      options: mapOptions,
    };

    await this.addMarkers(map, locations);
    return map;
  }

  private async createMarker(
    map: Map,
    location: MapLocation,
    index: number,
    active: boolean,
  ): Promise<google.maps.Marker> {
    const latLng = await this.googleMapsService.getLatLng(location.latitude, location.longitude);
    const icon = map.options?.icons?.shouldUseMaterialIcons
      ? this.getMaterialIcon(location, active)
      : await this.getIcon(map, location, index, active);
    const marker = await this.googleMapsService.addMarker({
      position: latLng,
      title: location.title,
      map: map.googleMap,
      zIndex: active ? 100 : index,
      ...icon,
    });

    if (map.options?.onClickMarkerFunction || map.options?.allowMarkerSelection) {
      marker.addListener('click', () => {
        map.options?.onClickMarkerFunction && map.options.onClickMarkerFunction(index, location.id);
        map.options?.allowMarkerSelection && this.setActiveMarker(map, index);
      });
    }

    return marker;
  }

  public async setActiveMarker(map: Map, index: number, centerActiveMarker = false) {
    if (map && map.markerList.length > 0 && !map.markerList[index]?.active) {
      await this.clearActiveMarkers(map);
      map.markerList[index].marker.setMap(null);
      const marker = await this.createMarker(map, map.markerList[index].location, index, true);
      map.markerList[index] = { marker: marker, active: true, location: map.markerList[index].location };
      if (centerActiveMarker) {
        const center = marker.getPosition();
        !center || map.googleMap?.panTo(center);
      }
    }
  }

  public async clearActiveMarkers(map: Map) {
    if (map) {
      for (let i = 0; i < map.markerList.length; i++) {
        if (map.markerList[i]?.active) {
          map.markerList[i].marker.setMap(null);
          const marker = await this.createMarker(map, map.markerList[i].location, i, false);
          map.markerList[i] = { marker: marker, active: false, location: map.markerList[i].location };
        }
      }
    }
  }

  private resetZoom(map: Map) {
    const zoom = map.googleMap?.getZoom() || null;
    if (zoom && zoom > this.MIN_ZOOM && map.options?.zoom) {
      map.googleMap?.setZoom(map.options.zoom);
    }
  }

  private async getIcon(
    map: Map,
    location: MapLocation,
    index: number,
    active: boolean,
  ): Promise<Partial<google.maps.MarkerOptions>> {
    let url = active ? location.activeIcon : location.icon;
    url ??= location.icon || this.getNumberedIcon(index, active);

    let scaledSize = active ? map.options?.icons?.activeIconSize : map.options?.icons?.iconSize;
    scaledSize ??= active ? this.DEFAULT_ACTIVE_ICON_SIZE : this.DEFAULT_ICON_SIZE;

    return {
      icon: {
        url: url,
        anchor: await this.googleMapsService.getPoint(scaledSize.x / 2, scaledSize.y / 2),
        scaledSize: await this.googleMapsService.getSize(scaledSize.x, scaledSize.y),
      },
    };
  }

  private getMaterialIcon(mapLocation: MapLocation, isActive = false): Partial<google.maps.MarkerOptions> {
    if (!mapLocation.icon) return {};
    return {
      ...(isActive ? DEFAULT_ACTIVE_MATERIAL_ICON_MARKER : DEFAULT_MATERIAL_ICON_MARKER),
      label: {
        ...DEFAULT_MATERIAL_ICON_MARKER.label,
        text: mapLocation.icon,
      },
    };
  }

  private getNumberedIcon(index: number, active: boolean): string {
    const markerUrl = active ? this.ACTIVE_MARKER_URL : this.MARKER_URL;
    return markerUrl + `${index + 1 > 9 ? index + 1 : '0' + (index + 1)}.svg`;
  }
}
