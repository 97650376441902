import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserRole } from '../../models/user-role.model';
import { AuthStorageService } from '../services/auth-storage.service';

export const authGuard = (role: UserRole | UserRole[], redirectRoute = '/login'): CanActivateFn => {
  return (route, state): boolean => {
    const authStorageService: AuthStorageService = inject(AuthStorageService);
    const router: Router = inject(Router);
    const userRole: UserRole | null = authStorageService.getUserRole();
    const token = authStorageService.getToken();
    const isAccessAllowed = checkIfCanAccess(role, userRole, token);

    if (!isAccessAllowed) {
      router.navigateByUrl(redirectRoute, { state: { redirectUrl: state.url } });
    }

    return isAccessAllowed;
  };
};

export const checkIfCanAccess = (
  allowedRole: UserRole | UserRole[],
  userRole: UserRole | null,
  token: string | null,
): boolean => {
  if (userRole) {
    const isRoleAllowed = Array.isArray(allowedRole) ? allowedRole.includes(userRole) : allowedRole === userRole;
    return !!token && isRoleAllowed;
  }
  return false;
};
