import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthStorageService } from '../services/auth-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authStorageService: AuthStorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authStorageService.getToken();
    const clonedRequest = token ? request.clone({ setHeaders: { authorization: `token ${token}` } }) : request;

    return next.handle(clonedRequest).pipe(
      catchError(error => {
        if (error.status === HttpStatusCode.Unauthorized) {
          this.authStorageService.clearAuthData();
        }
        return throwError(error);
      }),
    );
  }
}
