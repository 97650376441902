import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { environment } from '@environment';
import { AuthData } from '../../models/auth-data.model';
import { UserRole } from '../../models/user-role.model';
import { Brand } from '../../models/brand.model';
import { AuthDataBE } from '../../models/auth-data-be.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private readonly httpClient: HttpClient) {}

  login(email: string, password: string): Observable<AuthData> {
    const url = `${environment.apiEnv}api/rest-auth/login/`;
    return this.httpClient
      .post<AuthDataBE>(url, { email, password })
      .pipe(map(authDataBE => this.transformIntoAuthData(authDataBE)));
  }

  sendResetPasswordMail(email: string): Observable<boolean> {
    const url = `${environment.apiEnv}api/rest-auth/password/reset/`;
    return this.httpClient.post(url, { email }).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  resetPassword(uid: string, token: string, password: string, passwordConfirm: string): Observable<boolean> {
    const url = `${environment.apiEnv}api/rest-auth/password/reset/confirm/`;
    return this.httpClient.post(url, { uid, token, new_password1: password, new_password2: passwordConfirm }).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  checkToken(uid: string, token: string): Observable<boolean> {
    const url = `${environment.apiEnv}api/rest-auth/password/reset/check/`;
    return this.httpClient.post(url, { uid, token }).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  private calculateUserRole(authDataBE: AuthDataBE): UserRole | null {
    if (authDataBE.is_staff) {
      return UserRole.StaffUser;
    }
    if (authDataBE.brand === Brand.ESSENTIALIST) {
      return UserRole.EssentialistMember;
    }
    if (authDataBE.brand === Brand.AMEX) {
      return UserRole.AmexMember;
    }
    if (authDataBE.brand === Brand.LATAM) {
      return UserRole.LatamMember;
    }
    return null;
  }

  private transformIntoAuthData(authDataBE: AuthDataBE): AuthData {
    return {
      role: this.calculateUserRole(authDataBE),
      token: authDataBE.key,
      language: authDataBE.preferred_language,
    };
  }
}
