export enum ExperienceCategory {
  /* accommodation */
  APARTMENTS_CONDOS = 'apartments-condos',
  HOTEL = 'hotel',
  IN_MOTION = 'in-motion',
  SEA = 'sea',
  VILLA = 'villa',
  /* eat_drink */
  BARS = 'bars',
  FOOD_AND_WINE = 'food-and-wine',
  NIGHTLIFE = 'nightlife',
  RESTAURANTS = 'restaurants',
  SWEETS = 'sweets',
  /* experience */
  ARTS_CULTURE = 'arts-culture',
  EXPERIENCE = 'experience',
  FASHION_SHOPPING = 'fashion-shopping',
  FOOD_WINE = 'food-wine',
  HEALTH_WELLNESS = 'health-wellness',
  NATURE_OUTDOORS = 'nature-outdoors',
  SPORTS_ADVENTURE = 'sports-adventure',
  /* places_interest */
  CULTURE = 'culture',
  LANDMARKS = 'landmarks',
  SHOPS = 'shops',
  /* transportation*/
  AIR_TRANSPORTATION = 'air-transportation',
  LAND_TRANSPORTATION = 'land-transportation',
  WATER_TRANSPORTATION = 'water-transportation',
}

export enum ExperienceSubcategory {
  BAKERY = 'bakery',
  DESIGN = 'design',
  MUSEUM = 'museum',
  STYLE = 'style',
  ART_GALLERY = 'art-gallery',
  BOOKS_STATIONERY = 'books-stationery',
  LOCAL_MARKET = 'local-market',
  GARDEN = 'garden',
  PARK = 'park',
  NATURE = 'nature',
  ANTIQUES = 'antiques',
  SPA = 'spa',
  BEACH = 'beach',
  CAFE = 'cafe',
  LANDMARK = 'landmark',
  VINEYARD_WINERY = 'vineyard-winery',
  ARCHITECTURE = 'architecture',
  SPORTS_CENTER = 'sports-center',
  URBAN_SITE = 'urban-site',
  EAT = 'eat',
  ICE_CREAM = 'ice-cream',
  PROVISIONS = 'provisions',
  PERFORMING_ARTS = 'performing-arts',
  CULTURAL_CENTER = 'cultural-center',
  FLEA_MARKET = 'flea-market',
  NIGHTLIFE = 'nightlife',
  HOTEL = 'hotel',
  BOUTIQUE_HOTEL = 'boutique-hotel',
  LATIN_AMERICAN = 'latin-american',
  MIDDLE_EASTERN = 'middle-eastern',
  CARIBBEAN = 'caribbean',
  FUSION = 'fusion',
  MEXICAN = 'mexican',
  NIGHT_CLUB = 'night-club',
  INDEPENDENT_VILLA = 'independent-villa',
  EXPERIENCE = 'experience',
  LOCAL_COMMUNITIES = 'local-communities',
  LGBTQ = 'lgbtq',
  JAPANESE = 'japanese',
  STUDIO = 'studio',
  DRINK = 'drink',
  FOOD_EXPERIENCE = 'food-experience',
  SAFARI = 'safari',
  YOGA = 'yoga',
  HIKING = 'hiking',
  TENNIS = 'tennis',
  WINTER_SPORTS = 'winter-sports',
  SEAFOOD = 'seafood',
  MEDITATION = 'meditation',
  ACTIVITY_PARKS = 'activity-parks',
  OUTDOOR_EXHIBITION = 'outdoor-exhibition',
  SHOPPING_MALL = 'shopping-mall',
  ZOO = 'zoo',
  MONUMENT = 'monument',
  FERRY = 'ferry',
  BARS_AND_PUBS = 'bars-and-pubs',
  YACHT = 'yacht',
  ITALIAN = 'italian',
  STEAKHOUSE = 'steakhouse',
  ASIAN = 'asian',
  VEGETARIAN = 'vegetarian',
  EUROPEAN = 'european',
  FRENCH = 'french',
  AFRICAN = 'african',
  CUBAN = 'cuban',
  INDIAN = 'indian',
  PAINTING_DRAWING_POTTERY_CRAFTS = 'painting-drawing-pottery-crafts',
  PLACE_WORSHIP = 'place-worship',
  RYOKAN = 'ryokan',
  KIDS = 'kids',
  HOME_FURNISHINGS = 'home-furnishings',
  RV = 'rv',
  FARM_TABLE = 'farm-table',
  AMERICAN = 'american',
  ART_EXHIBITIONS_GALLERIES = 'art-exhibitions-galleries',
  HISTORY = 'history',
  MOUNTAINS = 'mountains',
  CULTURE = 'culture',
  SHOPPING = 'shopping',
  EXPLORING_CITY = 'exploring-city',
  FOOD_MARKETS = 'food-markets',
  FASHION = 'fashion',
  WATER_SPORTS = 'water-sports',
  FISHING = 'fishing',
  WALK = 'walk',
  CAR_RENTAL = 'car-rental',
  LAND_TRANSPORTATION_CAR_RENTAL = 'car_rental',
  FLIGHT = 'flight',
  BOAT = 'boat',
  BUS = 'bus',
  TRANSFER = 'transfer',
  TRAIN_TRANSPORTATION = 'train-transportation',
  HOTEL_VILLA = 'hotel-villa',
  CAMPGROUND = 'campground',
  SAILING = 'sailing',
  CRUISE = 'cruise',
  APARTMENT = 'apartment',
  CONDO = 'condo',
  PHOTOGRAPHY = 'photography',
  RELIGIOUS_INTERESTS = 'religious-interests',
  ARCHAEOLOGY = 'archaeology',
  WINE_TASTING = 'wine-tasting',
  PICNIC = 'picnic',
  COOKING_CLASS = 'cooking-class',
  SPA_SESSION = 'spa-session',
  WELLNESS_RETREAT = 'wellness-retreat',
  ADRENALINE_SPORTS = 'adrenaline-sports',
  MARTIAL_ARTS = 'martial-arts',
  PILATES = 'pilates',
  BIKING = 'biking',
  GOLF = 'golf',
  HORSEBACK_RIDING = 'horseback-riding',
  SPECTATOR_SPORTS = 'spectator-sports',
  TUBING = 'tubing',
  RIVER_SPORTS = 'river-sports',
  DRIVING = 'driving',
  CLIMBING = 'climbing',
  SCAVENGER_HUNT = 'scavenger-hunt',
  CINEMATOGRAPHY = 'cinematography',
  LIVE_CONCERT = 'live-concert',
  THEATER = 'theater',
  ANIMAL_INTERESTS = 'animal-interests',
  NATURE_WONDERS = 'nature-wonders',
  GARDENS = 'gardens',
  FARM = 'farm',
  CAMPING_GLAMPING = 'camping-glamping',
  STARGAZING = 'stargazing',
  ENJOYING_BEACH = 'enjoying-beach',
  BEACH_CLUB = 'beach-club',
  DEPARTMENT_STORE = 'department-store',
  DRIVE = 'drive',
  CANYONING = 'canyoning',
  SAILINGG = 'sailingg',
  BALLOONING = 'ballooning',
  SHOOTING_ARCHERY = 'shooting-archery',
  MEDITERRANEAN = 'mediterranean',
  TRAIN_ACCOMMODATION = 'train-accommodation',
  DANCE = 'dance',
  GLAMPING = 'glamping',
  BED_BREAKFAST = 'bed-breakfast',
  UNESCO_SITES = 'unesco-sites',
  SCANDINAVIAN = 'scandinavian',
  VEGAN = 'vegan',
  JEWELRY = 'jewelry',
  NATIONAL_PARKS = 'national-parks',
  RIVERS_LAKES = 'rivers-lakes',
}
