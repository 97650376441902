import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly DEFAULT_DURATION_IN_MS = 8000;
  constructor(private readonly snackBar: MatSnackBar) {}

  showSuccess(message: string) {
    this.snackBar.open(`✅\u00A0\u00A0${message}`, 'Close', { duration: this.DEFAULT_DURATION_IN_MS });
  }

  showSuccessAndAction$(message: string, action: string): Observable<void> {
    return this.snackBar.open(`✅\u00A0\u00A0${message}`, action).onAction();
  }

  showError(message: string, duration?: number) {
    this.snackBar.open(`🚫\u00A0\u00A0${message}`, 'Close', { duration });
  }

  showErrorAndAction$(message: string, action: string): Observable<void> {
    return this.snackBar.open(`🚫\u00A0\u00A0${message}`, action).onAction();
  }

  showMessage(message: string) {
    this.snackBar.open(message, 'Close');
  }
}
