import { Injectable } from '@angular/core';
import { ExperienceStatus } from '../../models/experience-status.model';
import { STATUS_ORDER_LIST } from '../../models/status-order';

@Injectable({
  providedIn: 'root',
})
export class ExperienceUtilsService {
  sortByStatus(status1: ExperienceStatus | null, status2: ExperienceStatus | null): number {
    if (status1 && status2) return STATUS_ORDER_LIST[status1] - STATUS_ORDER_LIST[status2];
    if (status1) return -1;
    if (status2) return 1;
    return 0;
  }
}
