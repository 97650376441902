export const NOTIFICATIONS = {
  Flights: {
    Api: {
      Alternative: 'Provided experience is an alternative',
    },
  },
  Unexpected: {
    Sorting: 'RoundFlight match not found when sorting',
  },
};
