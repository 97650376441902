import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthData } from '../../models/auth-data.model';
import { AuthStorageService } from './auth-storage.service';
import { AuthApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly authApiService: AuthApiService,
    private readonly authStorageService: AuthStorageService,
    private readonly router: Router,
  ) {}

  login(email: string, password: string, keepLogged: boolean, redirectUrl: string | null = '/'): Observable<AuthData> {
    this.authStorageService.clearAuthData();
    return this.authApiService.login(email, password).pipe(
      tap(authData => {
        this.authStorageService.saveAuthData(authData, keepLogged);
        redirectUrl && this.router.navigateByUrl(redirectUrl);
      }),
    );
  }

  logout(redirectUrl: string | null = '/login'): void {
    this.authStorageService.clearAuthData();
    redirectUrl && this.router.navigateByUrl(redirectUrl);
  }
}
