import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../utils/utils.service';

@Pipe({
  name: 'essTime',
  standalone: true,
})
export class TimePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}
  transform(value: string | null): string | null {
    // from HH:mm:ss to h:mm a
    return this.utilsService.getShortTime(value);
  }
}
