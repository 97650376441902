import { CreditCardType } from './credit-card-type.model';

export const CREDIT_CARD_IMAGE: { [K in CreditCardType]: string } = {
  [CreditCardType.VISA]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/visa.png',
  [CreditCardType.MASTERCARD]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/mastercard.png',
  [CreditCardType.AMERICAN_EXPRESS]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/amex.png',
  [CreditCardType.DINERS_CLUB]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/diners.png',
  [CreditCardType.DISCOVER]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/discover.png',
  [CreditCardType.HIPERCARD]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/hipercard.png',
  [CreditCardType.JCB]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/jcb.png',
  [CreditCardType.UNIONPAY]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/union_pay.png',
  [CreditCardType.DANKORT]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/dankort.png',
  [CreditCardType.FORBRUGSFORENINGEN]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/forbrugsforeningen.png',
  [CreditCardType.MAESTRO]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/maestro.png',
  [CreditCardType.ELO]:
    'https://res.cloudinary.com/thebellhop/image/upload/c_thumb,w_100,g_face/v1675173197/credit_cards/elo.png',
};

export const DEFAULT_CREDIT_CARD_IMAGE =
  'https://res.cloudinary.com/thebellhop/image/upload/v1628070048/assets/card/default-dark_2x.png';
