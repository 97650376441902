import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthStorageService } from '../services/auth-storage.service';

// to be placed before authGuard to move token from cookie to localstorage
export const cookieGuard: CanActivateFn = (): boolean => {
  const authStorageService = inject(AuthStorageService);
  authStorageService.moveCookieToken();
  return true;
};
